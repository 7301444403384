<template>
    <div>
        <!-- hero image -->
        <div
            class="z-1 bg-cover bg-center relative h-[20rem] sm:h-[40rem]"
            :style="`background-image: url(${heroImg})`"
        ></div>
        <div
            class="z-2 relative pt-8 sm:pt-16 lg:pt-24 mt-[-6vw] lg:mt-[-9vw] xl:mt-[-7.5vw] 2xl:mt-[-6vw] 3xl:mt-[-5.5vw]"
        >
            <div class="absolute w-full h-full top-0 left-0 overflow-hidden">
                <!-- blue/yellow wave -->
                <div
                    class="z-1 absolute top-0 left-0 w-full h-full bg-contain lg:bg-cover bg-no-repeat h-[40rem]"
                    :style="`background-position: top center; background-image: url(${require(theme === 'blue'
                        ? '~/assets/images/shapes/shape-7_blue-300.png'
                        : '~/assets/images/shapes/waves/wave-1-yellow-400.png')})`"
                >
                    <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-white" style="height: 30vh"></div>
                </div>
            </div>

            <!-- page content -->
            <div class="relative z-3">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Wrapper',
    props: {
        theme: {
            type: String,
            required: true,
            default: 'blue',
            validator: (value) => {
                return ['blue', 'yellow'].includes(value);
            },
        },
        heroImg: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
