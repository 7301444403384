<template>
    <div class="grid grid-cols-12 base-responsive-gap mt-6">
        <div class="col-span-12 lg:col-span-7">
            <h1 class="display">{{ title }}</h1>
            <div v-if="description" class="wysiwyg-content lead mt-8" v-html="description"></div>
            <p v-else-if="subtitle" class="lead mt-8">{{ subtitle }}</p>
        </div>

        <div class="col-span-12 lg:col-span-4 lg:col-start-9 mt-2 sm:mt-8 lg:mt-20">
            <slot name="details"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped></style>
