<template>
    <Wrapper :hero-img="model.media.hero_image.lg" :theme="model.background">
        <div class="relative pb-24">
            <div class="container relative z-2">
                <Breadcrumb :items="breadcrumbItems" />

                {{/* Intro block below the hero */}}
                <Intro :title="model.name" :description="model.description">
                    {{/* Details block on the right (optional) */}}
                    <template #details>
                        <div v-if="model.with_contact" class="grid grid-cols-1 gap-4 lg:gap-8">
                            {{/* Top part */}}
                            <div>
                                <div class="wysiwyg-content" v-html="model.details_text_primary"></div>
                                <Button
                                    v-if="model.details_cta_url_primary"
                                    :to="model.details_cta_url_primary"
                                    target="_blank"
                                    :label="model.details_cta_caption_primary || $t('generic.view')"
                                    class="mt-4"
                                />
                            </div>

                            {{/* Bottom part */}}
                            <div>
                                <div class="wysiwyg-content" v-html="model.details_text_secondary"></div>
                                <Button
                                    v-if="model.details_cta_url_secondary"
                                    :to="model.details_cta_url_secondary"
                                    target="_blank"
                                    :label="model.details_cta_caption_secondary || $t('generic.view')"
                                    class="mt-4"
                                />
                            </div>
                        </div>
                    </template>
                </Intro>
            </div>

            {{/* Content */}}
            <EditableContentComponentGroup :components="model.components" class="mt-4 lg:mt-24" />

            <section v-if="!!articles?.length" class="mt-16 sm:mt-20">
                <div class="container grid grid-cols-12 mb-10">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-7">
                        <h2 class="header-2"><T t="news.recommended_news" /></h2>
                    </div>
                </div>
                <CardCarousel type="article" :items="articles" />
            </section>
        </div>

        <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-200" style="height: 50vh"></div>
    </Wrapper>
</template>

<script>
import EditableContentComponentGroup from '@/components/Generic/EditableContentComponentGroup.vue';
import Wrapper from '@/components/Pages/Visitors/Wrapper.vue';
import Intro from '@/components/Pages/Visitors/Intro.vue';
import CardCarousel from '@/components/Carousels/CardCarousel/CardCarousel.vue';

export default {
    name: 'VisitorInformation',
    components: {
        CardCarousel,
        Intro,
        Wrapper,
        EditableContentComponentGroup,
    },
    async asyncData({ $axios, params, redirect, localePath, error, i18n, store }) {
        let model = null;
        let articles = null;

        await Promise.allSettled([
            $axios.$get(`/visitors/information/by-slug/${params.visitorInformation}`),
            $axios.$get(`/visitors/locations/by-slug/${params.visitorInformation}`),
        ])
            .then(([informationRes, locationRes]) => {
                if (informationRes.status === 'fulfilled' && informationRes.value) {
                    model = informationRes.value;
                } else if (locationRes.status === 'fulfilled' && locationRes.value) {
                    model = locationRes.value;
                }
            })
            .catch((err) => {
                console.log(err);
            });

        if (!model) {
            return await redirect(307, localePath('index'));
        }

        if (model.with_news) {
            await $axios
                .$get('/news?is_highlighted=1&limit=10')
                .then((res) => {
                    articles = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        store.commit('layout/setVisitorInformationLayoutTheme', model.background);

        await i18n.generateLocalizedRouteParams(model.locale_variants, 'visitorInformation');

        const breadcrumbItems = [];

        breadcrumbItems.push([i18n.t('navigation.visitor_information'), 'visitor-information']);
        breadcrumbItems.push([model.name, 'visitorInformation']);

        return {
            breadcrumbItems,
            articles,
            model,
        };
    },
    head() {
        return {
            title: this.$getPageTitle(this.model.metadata),
            meta: this.$getPageMetaData(this.model.metadata),
        };
    },
};
</script>

<style scoped></style>
